import React from 'react'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'

import { firstLetter } from 'common'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Header from './Header'
import Footer from './Footer'

import { FormProvider } from './FormContext'
import { BookingProvider } from './BookingContext'
import { translate } from '../translations/utils'
import Script from 'next/script'

const theme = createTheme({
  palette: {
    primary: {
      main: '#636aee'
    }
  }
})

const Layout = (pageProps) => {
  const headerProps = {
    nav: {
      classes: pageProps.nav && pageProps.nav.classes,
      fixed: pageProps.nav && pageProps.nav.fixed,
      color: pageProps.nav && pageProps.nav.color,
      light: pageProps.nav && pageProps.nav.light,
      dark: pageProps.nav && pageProps.nav.dark
    },
    headerClasses: pageProps.headerClasses,
    locale: pageProps.locale,
    defaultLocale: pageProps.defaultLocale,
    showOnlyLogo: pageProps.showOnlyLogo
  }

  let footerProps = {
    locale: pageProps.locale,
    defaultLocale: pageProps.defaultLocale
  }
  if (pageProps.footerProps) {
    footerProps = { ...footerProps, ...pageProps.footerProps }
  }

  const { locale, defaultLocale } = pageProps

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ paddingTop: pageProps.noPaddingTop ? '0' : '77px' }}
        className={pageProps.className}
      >
        <Head>
          <script
            async
            src='https://www.googletagmanager.com/gtag/js?id=G-WHCGSP07K5'
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-WHCGSP07K5');`}
          </script>

          <link
            rel='stylesheet'
            href='https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:ital,wght@0,300;0,400;0,700;1,400&display=swap'
          />
          <link rel='icon' href='/favicon.png' />
          <link
            href='https://use.fontawesome.com/releases/v5.8.1/css/all.css'
            rel='stylesheet'
          />
          <title>
            {pageProps.title
              ? `${firstLetter(pageProps.title)} - Gurubay`
              : translate('index_page_title', locale, defaultLocale)}
          </title>
          {/* <meta name="thumbnail" content="https://gurubay-front-res.s3.fr-par.scw.cloud/logo-square-padded.png" /> */}

          {/* <meta property="og:image" content={`${process.env.NEXT_PUBLIC_HOST}/content/img/oglogo.png`} key="og:image" />
        <meta property='og:image:type' content='image/png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='627' /> */}
          {/* <script type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `{
            "@context" : "http://schema.org",
            "@type" : "Organization",
            "name" : "Gurubay",
            "url" : "https://gurubay.co",
            "logo": "${process.env.NEXT_PUBLIC_HOST}/content/svg/logo-square.svg"
          }`
        }} /> */}

          {pageProps.title ? (
            <meta name='robots' content='nosnippet' />
          ) : (
            <meta
              name='description'
              content={translate('index_page_meta_desc', locale, defaultLocale)}
            />
          )}
        </Head>
        <NextNProgress color='#636aee' options={{ showSpinner: false }} />
        {!pageProps.hideHeader && <Header {...headerProps} />}

        {pageProps.listingForm || pageProps.bookingForm ? (
          <>
            {pageProps.listingForm && (
              <FormProvider>
                <main>{pageProps.children}</main>
              </FormProvider>
            )}
            {pageProps.bookingForm && (
              <BookingProvider>
                <main>{pageProps.children}</main>
              </BookingProvider>
            )}
          </>
        ) : (
          <main>{pageProps.children}</main>
        )}

        {!pageProps.hideFooter && <Footer {...footerProps} />}
      </div>
    </ThemeProvider>
  )
}

export default Layout
