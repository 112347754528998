import { isBrowser } from 'common'

function localStorageProvider() {
  // When initializing, we restore the data from `localStorage` into a map.
  if (isBrowser()) {
    try {
      const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))
      const saveToCache = () => {
        const appCache = []
        map.forEach((val, key) => {
          if (key.includes('userCrmMetric')) {
            console.debug(`skipping ${key}`)
          } else {
            console.debug(`storing ${key}`)
            appCache.push([key, val])
          }
        })

        localStorage.setItem('app-cache', JSON.stringify(appCache))
      }

      // Before unloading the app, we write back all the data into `localStorage`.
      window.addEventListener('beforeunload', saveToCache)

      setInterval(saveToCache, 1000 * 60 * 3)

      // We still use the map for write & read for performance.
      return map
    } catch (e) {
      return new Map()
    }
  } else {
    return new Map()
  }
}

export default localStorageProvider
