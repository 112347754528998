import React from 'react'

export const ScriptContext = React.createContext({
  placesLoaded: null,
  setPlacesLoaded: () => null
})

export const ScriptProvider = ({ placesLoaded, setPlacesLoaded, children }) => (
  <ScriptContext.Provider value={{ placesLoaded, setPlacesLoaded }}>
    {children}
  </ScriptContext.Provider>
)
