import React from 'react'
import useSWR from 'swr'
import { PacksApi, UsersApi } from 'typescript-axios'
import { getAxiosParams } from '../utils/client'

const PACK_SERVICE = new PacksApi(getAxiosParams())
const USER_SERVICE = new UsersApi(getAxiosParams())

export const UserContext = React.createContext({
  loggedUser: null,
  setLoggedUser: () => null,
  calendarView: null,
  setCalendarView: () => null,
  calendarMin: null,
  setCalendarMin: () => null,
  calendarMax: null,
  setCalendarMax: () => null,
  isGoogleCalendarSignedIn: null,
  setIsGoogleCalendarSignedIn: () => null,
  isInstaSignedIn: null,
  setIsInstaSignedIn: () => null,
  guruCode: null,
  setGuruCode: () => null,
  remainingTickets: null,
  setRemainingTickets: () => null
})

export const UserProvider = ({
  loggedUser,
  setLoggedUser,
  calendarView,
  setCalendarView,
  calendarMin,
  setCalendarMin,
  calendarMax,
  setCalendarMax,
  isGoogleCalendarSignedIn,
  setIsGoogleCalendarSignedIn,
  isInstaSignedIn,
  setIsInstaSignedIn,
  guruCode,
  setGuruCode,
  remainingTickets,
  setRemainingTickets,
  children
}) => {
  useSWR(
    loggedUser ? '/me' : null,
    () => USER_SERVICE.readUserMeApiV1UsersMeGet().then((res) => res.data),
    {
      refreshInterval: 2 * 60 * 1000,
      dedupingInterval: 1 * 60 * 1000
    }
  )

  // store the preference
  React.useEffect(() => {
    if (!loggedUser) {
      localStorage.removeItem('loggedUser')
      setIsGoogleCalendarSignedIn(false)
      setIsInstaSignedIn(false)
      setRemainingTickets(null)
    } else {
      if (remainingTickets === null) {
        PACK_SERVICE.readRemainingTicketsApiV1PacksRemainingGet()
          .then((res) => setRemainingTickets(res.data))
          .catch((e) => {
            console.error(e)
          })
      }
      localStorage.setItem('loggedUser', JSON.stringify(loggedUser))
    }
  }, [loggedUser])

  React.useEffect(() => {
    if (!!guruCode && (!loggedUser || loggedUser.guru_code != guruCode)) {
      localStorage.setItem('guruCode', guruCode)
    } else if (!guruCode) {
      localStorage.removeItem('guruCode')
    }
  }, [guruCode])

  React.useEffect(() => {
    if (calendarView) {
      localStorage.setItem('calendarView', JSON.stringify(calendarView))
    }
  }, [calendarView])

  React.useEffect(() => {
    if (calendarMin) {
      localStorage.setItem('calendarMin', calendarMin.toJSON())
    }
  }, [calendarMin])

  React.useEffect(() => {
    if (calendarMax) {
      localStorage.setItem('calendarMax', calendarMax.toJSON())
    }
  }, [calendarMax])

  return (
    <UserContext.Provider
      value={{
        loggedUser,
        setLoggedUser,
        calendarView,
        setCalendarView,
        calendarMin,
        setCalendarMin,
        calendarMax,
        setCalendarMax,
        isGoogleCalendarSignedIn,
        setIsGoogleCalendarSignedIn,
        isInstaSignedIn,
        setIsInstaSignedIn,
        guruCode,
        setGuruCode,
        remainingTickets,
        setRemainingTickets
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
